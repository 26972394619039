<template>
  <section class="container" v-title data-title="" >
    <!--新闻分类列表-->
    <section class="detail-con" v-if="itemData!==null && itemData!== undefined" >
      <div v-if="showView">
         <h2 class="article-title">{{itemData.Title}}</h2>         
         <span class="title icon-style">
          <i><img class="icon time" src="../../assets/images/time.png">{{itemData.DateCreated}}</i>
          <i><img class="icon read" src="../../assets/images/read.png">{{itemData.ViewCount}}</i>
         </span>
         <span class="title author-style"><i class="author">来源：{{itemData.Author}}</i></span>
      </div>
      <div class="detail-text" v-html="itemData.Content"></div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
  data(){
    return{
      itemData:[],     
      query:{
       Id:this.$route.query.id
      },
      showView:false
    }
   
  },
  mounted(){  
    this.getData();   
  },
  methods:{
    //获取数据  
    getData(){ 
      var _this=this;
      this.$get(this.$api.GetArticle,{
        ...this.query
      })
      .then((res)=>{
        console.log(res)
        _this.itemData = res; 
        _this.showView = true;  
        if(res&&res.DateCreated){   
          res.DateCreated = moment(res.DateCreated).format('YYYY.MM.DD')
        }       
      }).catch(err=>{console.log(err);});
    },
  }
}
</script>
<style lang="stylus">
iframe{
    width: 98%;
    min-height: 25rem;
    height: 100%;
}
/**资讯列表样式 */
a{color:#0089CD;}
.detail-con{
  display: block;
  min-height:600px;
  padding:0 16px;
  background: #fff;
  h3{
    display: block;
    border-bottom: 2px solid #d2dae7;
    line-height: 70px;
    font-size: 28px;
    color: #374567;
    margin-bottom:14px;
  }
  h2{
    display:block;
    font-size:22px;
    color:#374567;
    line-height:30px;
    text-align: left;
    font-weight: 600;
    margin: 0 0 20px 10px;
    padding-top:10px;
  }

  .article-title::before{
    float: left;
    content: '';
    height: 25px;
    width:5px;
    background: #3385FF;
    margin-top:3px;
    margin-left: -15px;
   }
   .author-style{
     width 100%;
     margin:5px 0 10px 0px !important;
     i{
       margin:0 !important;
     }
   }
   .icon-style{
     margin-left 1px !important
   }
  .title{
    display:inline-block;
    text-align: left;
    vertical-align: middle;
    font-size: 16px;
    color: #7A8BA8;
    author{
      line-height 24px
    }
    i{
      float: left;
      font-style: normal;
      margin-right: 20px;
    }
    .icon{
      float: left;
    }
    .time{
      width:15px;
      height: 15px;
      margin-top: 5px;
      margin-right 7px
    }
    .read{
      width: 25px;
      height: 25px;
      margin-top: -1px;
    }
  }

  .detail-text{
    display: block;
    font-size: 19px !important;
    color: #374567;
    line-height: 34px;
    min-height:300px;
    text-align: left !important;
    border-bottom:2px solid #eee;
    word-wrap: break-word;
    h2,h3,h4,h5{
      text-indent:inherit !important;
    }
    span{
      font-size: 19px !important;
    }
    p{
      font-size: 19px !important;
      text-indent:inherit !important;
    }
    img{
      width:100% !important;
      height:auto !important;
    }
  }
}
</style>
